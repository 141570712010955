import React,{useRef} from 'react';
import useInViewport from '../../lib/hooks/useInViewport';
import Image from "next/image";

const HomeComunicadosImage = ({imageName}) => {

    const ref = useRef();
    const inViewport = useInViewport(ref, '0px');

    return(

        <img src={process.env.NEXT_PUBLIC_PATH + imageName} alt="" className={`zoom-in ${inViewport? 'animation' : ''}`} ref={ref}></img>

    )
}

export default HomeComunicadosImage;
