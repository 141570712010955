
import React, {useEffect, useState, useRef} from 'react';
import Slider from "react-slick";
import Link from 'next/link';
import { useRecoilValue } from "recoil";
import { configStoreData } from "../../selectors/store";

import HomeComunicadosImage from "./HomeComunicadosImage";
import ContentImage from '../common/ContentImage.jsx';

const HomeCatalogoSection = (props) => {
	const storeId = useRecoilValue(configStoreData);
    const homePublishinglines = props?.publishingLines?.data;
    const homeReleases =props?.homeReleases?.data;

    return (
        <div className="box3 pt-20">
            <div className="container main-container">
            <div className="row">				
                <div className={storeId == 1 ? "col-sm-8 padmar" : "col-sm-12 padmar"}>
					<div className="box--wrap marg">
						<h6 className="novedades">{storeId == 1 ? "CATÁLOGO" : "PRODUCTOS"}</h6>
						<h2 className="A-la-venta-esta-sema ">{storeId == 1 ? "Explora nuestro catálogo" : "Editoriales destacadas"}</h2>
						<div className="line4"></div>
					</div>
					<div className="catalogo">
						{homePublishinglines?.map((val,i) => (
							<li key={`publishingLine${i}`} className="eccPublishingLine">
								<Link href={`/sellos/${val.slug}`} passHref={true}>
									<div className="wrapper">                                    
										<ContentImage imageName={val?.image} isImageComponent={false}/>
										<img className="publishLineHover" src={process.env.NEXT_PUBLIC_PATH + val?.homeHover} alt=""></img>
										{<label><Link href={`/sellos/${val.slug}`} passHref={true}>{val.publishingLine}</Link></label>}
									</div>
								</Link>
							</li>
						))}
					</div>
                </div>
				{storeId == 1 &&
                <div className="col-sm-4 padmar">
					<div className="box--wrap marg">
						<h6 className="novedades">NOVEDADES</h6>
						<h2 className="A-la-venta-esta-sema ">Comunicados</h2>
						<div className="line4"></div>
						<section className="regular image_slider_homereleases">
						<Slider>
							{homeReleases?.map((val,i) => (
								<div  key={`HomeRel${i}`} className="image_slider_homereleases_content">
									<Link passHref={true} href={`/contenidos/${val.slug}`}>
										<div className="comunicados-wrapper">
										<div className="img-wrapper">
											<HomeComunicadosImage imageName={val?.image}/>
										</div>
										<h3>{val.title}</h3>
										</div>
									</Link>
									<div dangerouslySetInnerHTML={{ __html:val.shortContent}}></div>
								</div>
							))}
						</Slider>
						</section>
					</div>
                </div>}
            </div>
            </div>
        </div>
    )
}

export default HomeCatalogoSection;
